import React from 'react';
import Footer from './footer';
import Header from './header';
import './layout.css';
import StyleInjector from './style-injector';
import PropTypes from 'prop-types';
const Layout = ({ children }) => {
  return (
    <StyleInjector>
      <Header />
      {children}
      <Footer />
    </StyleInjector>
  );
};
Layout.propTypes = {
  children: PropTypes.node,
};
export default Layout;
