import React from 'react';
import { Button, TextField } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '@material-ui/lab/Alert';
const fields = [
  {
    label: 'Name',
    id: 'name',
    autoComplete: 'name',
  },
  {
    label: 'Email',
    id: 'email',
    autoComplete: 'email',
  },
  {
    label: 'Message',
    id: 'message',
    numLines: 4,
  },
];
const defaultFormInput = {
  name: '',
  email: '',
  message: '',
};
const ContactForm = () => {
  const [formInput, setFormInput] = React.useState(defaultFormInput);
  const [msg, setMsg] = React.useState(null);
  const [formInputErrors, setFormInputErrors] = React.useState({});
  const recaptchaRef = React.createRef();
  const submit = async () => {
    if (!validateFormInput()) return;
    const data = JSON.stringify({
      ...formInput,
      'g-recaptcha-response': await recaptchaRef.current.executeAsync(),
    });
    const xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.GATSBY_FORMSPREE_URL);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setFormInput(defaultFormInput);
        setMsg({
          message: 'Form submitted successfully.',
          severity: 'success',
        });
      } else {
        setMsg({
          message: 'Form failed to submit. Please try again later.',
          severity: 'error',
        });
      }
    };
    xhr.send(data);
  };
  const validateFormInput = () => {
    let errors = {};
    if (formInput.name === '') errors.name = 'Name cannot be empty.';
    if (
      formInput.email.search(
        /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/
      ) !== 0
    )
      errors.email = 'Invalid email.';
    if (formInput.message === '') errors.message = 'Please enter a message.';
    setFormInputErrors(errors);
    return Object.keys(errors).length === 0;
  };
  return (
    <div
      style={{
        textAlign: 'center',
        marginBottom: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        marginTop: 16,
      }}
    >
      {msg ? <Alert severity={msg.severity}>{msg.message}</Alert> : null}
      {fields.map((data, i) => (
        <div style={{ margin: 16 }} key={i}>
          <TextField
            required
            variant={data.variant || 'outlined'}
            label={data.label}
            style={{ width: 300 }}
            autoComplete={data.autoComplete}
            rows={data.numLines}
            multiline={Boolean(data.numLines)}
            value={formInput[data.id]}
            helperText={formInputErrors[data.id]}
            error={Boolean(formInputErrors[data.id])}
            color="secondary"
            onChange={({ target: { value } }) => {
              setFormInput((d) => ({
                ...d,
                [data.id]: value,
              }));
            }}
          />
        </div>
      ))}
      <div
        style={{
          textAlign: 'center',
          marginLeft: 'auto',
          display: 'inline-block',
          marginBottom: 12,
        }}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_CAPTCHA_KEY}
          badge="inline"
        />
      </div>
      <div>
        <Button
          onClick={submit}
          color="secondary"
          variant="contained"
          size="large"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
