import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import Layout from './layout';
import { Provider } from 'react-redux';
import store from '../redux/store';

const TopLayout = (props) => {
  return (
    <>
      <Helmet defaultTitle="Bellisima - You are Beautiful Inside and Out">
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
        <Provider store={store}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Layout>{props.children}</Layout>
        </Provider>
    </>
  );
};

TopLayout.propTypes = {
  children: PropTypes.node,
};
export default TopLayout;
