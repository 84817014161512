import React from 'react';
import ContactDetails from './contact-details';
import ContactForm from './contact-form';
const Contact = () => {
  return (
    <>
      <ContactDetails />
      <ContactForm />
    </>
  );
};

export default Contact;
