import LongText from './long-text';
import ContactDetails from './contact-details';
import ContactForm from './contact-form';
import Contact from './contact';
import Footer from './footer';
import Header from './header';
import Layout from './layout';
import NavDrawer from './nav-drawer';
import PageHead from './page-head';
import ShortBio from './short-bio';
import StyleInjector from './style-injector';
import TestimonialBlock from './testimonial-block';
import Hero from './hero';
import InfoAccordion from './info-accordion';
import Slideshow from './slideshow';
import CustomImage from './custom-image';

const components = [
  { component: LongText, name: 'ContentfulTextBlock' },
  { component: ContactDetails, name: 'ContentfulContactDetails' },
  { component: ContactForm, name: 'ContentfulContactForm' },
  { component: Contact, name: 'ContentfulContact' },
  { component: Footer, name: 'ContentfulFooter' },
  { component: Header, name: 'ContentfulHeader' },
  { component: Layout, name: 'ContentfulLayout' },
  { component: NavDrawer, name: 'ContentfulNavDrawer' },
  { component: PageHead, name: 'ContentfulPageHead' },
  { component: ShortBio, name: 'ContentfulShortBio' },
  { component: StyleInjector, name: 'ContentfulStyleInjector' },
  { component: TestimonialBlock, name: 'ContentfulTestimonialBlock' },
  { component: Hero, name: 'ContentfulHero' },
  { component: InfoAccordion, name: 'ContentfulInfoAccordionBlock' },
  { component: Slideshow, name: 'ContentfulSlideshow' },
  { component: CustomImage, name: 'ContentfulImage' },
];
export default components;
