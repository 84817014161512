import React from 'react';
import {
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import Menu from '@material-ui/icons/Menu';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import NavDrawer from './nav-drawer';
import pages from '../data/pages';
import { connect } from 'react-redux';
import navigate from '../utils/navigate';
import PropTypes from 'prop-types';
const Header = (props) => {
  const data = useStaticQuery(query);
  const [navDrawer, setNavDrawer] = React.useState(false);
  const openNavDrawer = () => {
    setNavDrawer(true);
  };
  const closeNavDrawer = () => {
    setNavDrawer(false);
  };
  return (
    <div style={{ minHeight: 100 }}>
      <Hidden lgUp>
        <Grid container justify="center">
          <Grid item xs={10} style={{ textAlign: 'center' }}>
            <Link to="/">
              <img
                src={data.logo.file.url}
                style={{
                  marginLeft: 'auto',
                  textAlign: 'center',
                  marginRight: 'auto',
                  marginTop: 12,
                  maxWidth: 250,
                }}
              />
            </Link>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
          }}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={9}>
            {data.contactDetails.phone ? (
              <OutboundLink
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  padding: 0,
                  margin: 0,
                }}
                href={
                  'tel:' +
                  data.contactDetails.phone.replace(' ', '').replace('-', '')
                }
              >
                <Typography align="left">
                  {data.contactDetails.phone}
                </Typography>
              </OutboundLink>
            ) : null}
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            xs={3}
          >
            <IconButton onClick={openNavDrawer}>
              <Menu />
            </IconButton>
          </Grid>
        </Grid>
        <NavDrawer open={navDrawer} closeNavDrawer={closeNavDrawer} />
      </Hidden>
      <Hidden mdDown>
        <div style={{ height: 100 }}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Link to="/">
                <img src={data.logo.file.url} style={{ maxHeight: 100 }} />
              </Link>
            </Grid>
            <Grid
              container
              item
              xs={7}
              direction="row"
              display="flex"
              justify="center"
            >
              {pages.map((page, i) => (
                <Button
                  to={page.path}
                  onClick={() =>
                    page.path
                      ? navigate(page.path)
                      : window?.open(page.href, '_blank')
                  }
                  key={i}
                  style={{ margin: 24 }}
                  large
                  variant={props.reduxPath === page.path ? 'outlined' : 'text'}
                >
                  {page.name}
                </Button>
              ))}
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </div>
  );
};

const query = graphql`
  query {
    logo: contentfulAsset(contentful_id: { eq: "3Te4ly0ktRRVg6JPjJtjrM" }) {
      file {
        url
      }
    }
    contactDetails: contentfulContactDetails(
      contentful_id: { eq: "3dIOralwdEsODUCmPBIFDx" }
    ) {
      phone
    }
  }
`;
Header.propTypes = {
  reduxPath: PropTypes.string,
};
const mapStateToProps = (state) => {
  return { reduxPath: state.path };
};
export default connect(mapStateToProps, null)(Header);
