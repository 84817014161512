import React from 'react';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteRounded';
import theme from '../theme';
import TestimonialCard from './testimonial-card';
import PropTypes from 'prop-types';
const Testimonial = (props) => {
  return (
    <div style={{ margin: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <Divider
          style={{
            position: 'relative',
            top: 24,
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <IconButton
          disableRipple
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }}
        >
          <FormatQuoteIcon />
        </IconButton>
      </div>
      <Typography align="center" variant="h5">
        Testimonials
      </Typography>
      <Grid
        container
        spacing={4}
        display="flex"
        justify="center"
        style={{ paddingTop: 16 }}
      >
        {!props.data ? null : props.data.testimonials ? (
          props.data.testimonials.map((t, i) => (
            <TestimonialCard key={i} data={t} />
          ))
        ) : props.data.testimonial ? (
          <TestimonialCard data={props.data} />
        ) : null}
      </Grid>
    </div>
  );
};
Testimonial.propTypes = {
  data: PropTypes.shape({
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        testimonial: PropTypes.shape({
          testimonial: PropTypes.string,
        }),
        name: PropTypes.string,
      })
    ),
    testimonial: PropTypes.shape({
      testimonial: PropTypes.string,
    }),
  }),
};
export default Testimonial;
