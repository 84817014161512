import React from 'react';
import { Fade } from 'react-slideshow-image';
import Image from 'gatsby-image';
import 'react-slideshow-image/dist/styles.css';
import PropTypes from 'prop-types';
const Slideshow = (props) => {
  return (
    <Fade
      style={{
        maxWidth: 300,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      arrows={false}
      pauseOnHover={false}
    >
      {props.data.images.map(({ image }, i) => (
        <div className="each-fade" key={i}>
          <div className="image-container">
            <Image
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              fixed={image.fixed}
            />
          </div>
        </div>
      ))}
    </Fade>
  );
};
Slideshow.propTypes = {
  data: PropTypes.shape({ images: PropTypes.array }),
};
export default Slideshow;
