import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Grid, Hidden, Typography } from '@material-ui/core';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
const LongText = (props) => {
  const contentfulRenderOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return <Typography variant="h1">{children}</Typography>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <Typography variant="h2">{children}</Typography>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <Typography variant="h3">{children}</Typography>;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <Typography variant="h4">{children}</Typography>;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <Typography variant="h5">{children}</Typography>;
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return <Typography variant="h6">{children}</Typography>;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Typography
            variant="body1"
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            {children}
          </Typography>
        );
      },
      [BLOCKS.HR]: () => {
        return (
          <div
            style={{
              paddingTop: 24,
              paddingBottom: 24,
              maxWidth: 450,
            }}
          />
        );
      },
    },
  };
  const longText = JSON.parse(props.data.longText.raw);
  return (
    <>
      <Grid
        container
        display="flex"
        justify="center"
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Grid item xs={11} sm={8} md={6}>
          {props.data.title !== '' ? (
            <Typography variant="h4">{props.data.title}</Typography>
          ) : null}
          {props.data.subTitle !== '' ? (
            <Typography variant="h5" color="primary">
              {props.data.subTitle}
            </Typography>
          ) : null}
          {props.data.image && props.data.image.fluid ? (
            <>
              <Hidden mdUp>
                <GatsbyImage
                  style={{
                    maxWidth: 300,
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  fluid={props.data.image.fluid}
                />
              </Hidden>
              <Hidden smDown>
                <GatsbyImage
                  style={{ width: 250, float: 'right', margin: 8 }}
                  fluid={props.data.image.fluid}
                />
              </Hidden>
            </>
          ) : null}
          {props.data
            ? documentToReactComponents(longText, contentfulRenderOptions)
            : null}
        </Grid>
      </Grid>
    </>
  );
};
LongText.propTypes = {
  data: PropTypes.shape({
    longText: PropTypes.object.isRequired,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
  }),
};
export default LongText;
