import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import PropTypes from 'prop-types';
import theme from '../theme';
import navigate from '../utils/navigate';
const Hero = ({ data, variant }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        color:
          variant === 'secondary'
            ? theme.palette.secondary.contrastText
            : theme.palette.primary.contrastText,
      }}
    >
      <Typography variant="h2" style={{ fontFamily: 'yesteryear' }}>
        {data.bigText}
      </Typography>
      {data.smallText ? (
        <Typography variant="h5">{data.smallText}</Typography>
      ) : null}
      {data.link && data.btnText ? (
        <Button
          style={{ marginTop: 8 }}
          color={variant === 'secondary' ? 'primary' : 'secondary'}
          variant="contained"
          to={data.link}
          onClick={() => navigate(data.link)}
        >
          {data.btnText}
        </Button>
      ) : null}
    </div>
  );
};
Hero.propTypes = {
  data: PropTypes.shape({
    bigText: PropTypes.string.isRequired,
    smallText: PropTypes.string,
    btnText: PropTypes.string,
    link: PropTypes.string,
  }),
  variant: PropTypes.string,
};
export default Hero;
