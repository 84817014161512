import React from 'react';
import theme from '../../src/theme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PropTypes from 'prop-types';
const Theme = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
Theme.propTypes = {
  children: PropTypes.node,
};
export default Theme;
