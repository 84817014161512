import React from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import navigate from '../utils/navigate';
import theme from '../theme';
const ShortBio = ({ data }) => {
  return (
    <div style={{ margin: 32, textAlign: 'center' }}>
      <Grid container display="flex" justify="center">
        <Grid item xs={10} md={5} lg={4}>
          <Typography variant="h3">{data.name}</Typography>
          {data.position ? (
            <Typography
              variant="h5"
              style={{ color: theme.palette.secondary.dark, fontWeight: 700 }}
            >
              {data.position}
            </Typography>
          ) : null}
          {data.shortBioText ? (
            <Typography>{data.shortBioText}</Typography>
          ) : null}
          {data.link && data.btnText ? (
            <Button
              to={data.link}
              onClick={() => navigate(data.link)}
              style={{ marginTop: 16 }}
              variant="contained"
              color="primary"
            >
              {data.btnText}
            </Button>
          ) : null}
        </Grid>
        <Hidden smDown>
          <Grid item md={2}>
            <GatsbyImage
              fixed={data.image.fixed}
              imgStyle={{ paddingLeft: 12 }}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};
ShortBio.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
    shortBioText: PropTypes.string,
    btnText: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.shape({
      fixed: PropTypes.object,
    }),
  }),
};
export default ShortBio;
