import { UPDATE_PATH } from '../actionTypes';

const initialState = {
  path: typeof window !== 'undefined' ? window.location.pathname : null,
};
export default (state, action) => {
  let newState = { ...initialState, ...state };
  switch (action.type) {
    case UPDATE_PATH:
      newState.path = action.data;
      if (action.data.endsWith('/') && action.data.length > 1)
        newState.path = action.data.slice(0, -1);
      break;
  }
  return newState;
};
