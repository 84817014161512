import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#fff',
      // dark: '#a00037',
      // light: '#ffc0cb',
    },
    secondary: {
      main: '#d3d3d3',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
});

export default theme;
