import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const TestimonialCard = (props) => {
  return (
    <Grid item xs={12} sm={4} md={3} style={{ maxWidth: 500 }}>
      <Card style={{ textAlign: 'center' }} variant="outlined">
        <CardContent>
          <Typography>
            &quot;{props.data.testimonial.testimonial}&quot;
          </Typography>
          <Typography variant="h6">- {props.data.name}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
TestimonialCard.propTypes = {
  data: PropTypes.shape({
    testimonial: PropTypes.shape({
      testimonial: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
};
export default TestimonialCard;
