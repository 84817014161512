import React from 'react';
import theme from '../theme';
import PropTypes from 'prop-types';
import GenerateReact from './generate-react';
const SlantedSection = (props) => {
  let data = null;
  let small = props.small;
  let large = props.large;
  let topFlat = props.topFlat;
  let bottomFlat = props.bottomFlat;
  let variant = props.variant;
  if (props.data) {
    data = { page: { modules: props.data.modulesInSlanted } };
    if (props.data.size) large = true;
    else small = true;
    if (props.data.topFlat) topFlat = true;
    if (props.data.bottomFlat) bottomFlat = true;
    if (props.data.variant) variant = 'primary';
    else variant = 'secondary';
  }
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : variant === 'secondary'
          ? theme.palette.secondary.light
          : undefined,
        paddingBottom: bottomFlat ? 16 : 16,
        paddingTop: topFlat ? 16 : 20,
        clipPath:
          variant === 'secondary'
            ? `polygon(0 ${topFlat ? '0' : '20'}%, 100% 0, 100% 100%, 0 ${
                bottomFlat ? '100' : '80'
              }%)`
            : undefined, // props.bottomFlat ? 'polygon(0 20%, 100% 0, 100% 100%, 0 100%)' : 'polygon(0 20%, 100% 0, 100% 100%, 0 80%)',
        /* left top, right top, right bottom, left bottom, left bottom */
        ...props.style,
      }}
    >
      <div
        style={{
          marginTop:
            variant === 'primary'
              ? undefined
              : topFlat
              ? 0
              : large
              ? 32
              : small
              ? 16
              : 0,
          marginBottom:
            variant === 'primary'
              ? undefined
              : bottomFlat
              ? 0
              : large
              ? 32
              : small
              ? 16
              : 0,
        }}
      >
        {data ? (
          <GenerateReact slantedSection variant={variant} data={data} />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};
SlantedSection.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  large: PropTypes.bool,
  backgroundColor: PropTypes.string,
  bottomFlat: PropTypes.bool,
  topFlat: PropTypes.bool,
  style: PropTypes.object,
  data: PropTypes.object,
  variant: PropTypes.string,
};
export default SlantedSection;
