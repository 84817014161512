import {
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import theme from '../theme';
const ContactDetails = () => {
  const {
    ContactDetails: { phone, addresses, email, message },
  } = useStaticQuery(graphql`
    query ContactDetails {
      ContactDetails: contentfulContactDetails(
        contentful_id: { eq: "3dIOralwdEsODUCmPBIFDx" }
      ) {
        addresses {
          street1
          street2
          city
          state
          zipCode
          name
        }
        email
        phone
        message
      }
    }
  `);
  return (
    <>
      <Grid
        container
        justify="center"
        display="flex"
        style={{ maxWidth: 1000, marginLeft: 'auto', marginRight: 'auto' }}
      >
        {addresses.map(
          ({ name, city, state, zipCode, street1, street2 }, i) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              justify="center"
              style={{ marginLeft: 12, marginRight: 12 }}
              key={i}
            >
              <Card
                style={{
                  textAlign: 'center',
                  maxWidth: 250,
                  marginTop: 12,
                  marginBottom: 12,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                variant="outlined"
              >
                <CardContent>
                  <Typography>
                    <strong>{name}</strong>
                  </Typography>
                  <Typography>{street1}</Typography>
                  {street2 !== '' ? <Typography>{street2}</Typography> : null}
                  <Typography>
                    {city}, {state} {zipCode}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ maxWidth: 500, marginRight: 'auto', marginLeft: 'auto' }}
      >
        {[
          {
            text: phone,
            icon: (
              <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
            ),
          },
          {
            text: email,
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
              </svg>
            ),
          },
        ].map(({ text, icon }, i) => {
          return text && icon ? (
            <React.Fragment key={i}>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: 'right',
                  paddingRight: 16,
                  paddingTop: i != 0 ? 16 : undefined,
                }}
              >
                <SvgIcon
                  style={{
                    verticalAlign: 'middle',
                    color: theme.palette.secondary.dark,
                  }}
                >
                  {icon}
                </SvgIcon>
              </Grid>
              <Grid item xs={7} style={{ paddingTop: i != 0 ? 16 : undefined }}>
                <Typography variant="h6">{text}</Typography>
              </Grid>
            </React.Fragment>
          ) : null;
        })}
        <Typography
          variant="body1"
          align="center"
          style={{ paddingTop: 12, marginLeft: 16, marginRight: 16 }}
        >
          {message}
        </Typography>
      </Grid>
    </>
  );
};

export default ContactDetails;
