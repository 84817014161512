import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
const CustomImage = (props) => {
  return (
    <div
      style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <Image fixed={props.data.image.fixed} />
    </div>
  );
};
CustomImage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};
export default CustomImage;
