import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../theme';

const PageHead = (props) => {
  return (
    <>
      <div
        style={{
          padding: 32,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Grid container display="flex" justify="center">
          <Grid item xs={11} sm={8}>
            <Typography variant="h2">{props.data.mainText}</Typography>
            <Typography variant="h5">{props.data.subText}</Typography>
          </Grid>
        </Grid>
      </div>
      <Divider style={{ margin: 24 }} />
    </>
  );
};
PageHead.propTypes = {
  data: PropTypes.shape({
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    textColor: PropTypes.string,
  }),
};
export default PageHead;
