import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import navigate from '../utils/navigate';
import pages from '../data/pages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
const NavDrawer = (props) => {
  const nav = (page) => {
    props.closeNavDrawer();
    if (page.path) navigate(page.path);
    else window?.open(page.href, '_blank');
  };
  return (
    <Drawer onClose={props.closeNavDrawer} open={props.open} anchor="right">
      {pages.map((page, i) => (
        <List style={{ minWidth: 250 }} key={i}>
          <ListItem
            style={{
              backgroundColor: page.path == props.reduxPath ? '#dcdcdc' : null,
            }}
            button
            onClick={() => nav(page)}
          >
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText>{page.name}</ListItemText>
          </ListItem>
        </List>
      ))}
    </Drawer>
  );
};
NavDrawer.propTypes = {
  closeNavDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  reduxPath: PropTypes.string,
};

const mapStateToProps = (state) => ({ reduxPath: state.path });
export default connect(mapStateToProps, null)(NavDrawer);
