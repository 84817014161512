import React from 'react';
import components from './components';
import SlantedSection from './slanted-section';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

const GenerateReact = (props) => {
  const noDividerNextNames = (name) => {
    if (!name) return true;
    return !['ContentfulTestimonialBlock', 'ContentfulSlantedSection'].includes(
      name
    );
  };
  return props.data ? (
    <>
      {props.data.page.modules.map((m, j, arr) => {
        components.push({
          component: SlantedSection,
          name: 'ContentfulSlantedSection',
        });
        for (let i = 0; i < components.length; i++) {
          if (components[i].name === m.__typename) {
            const C = components[i].component;
            return (
              <>
                <C key={j} data={m} variant={props.variant} />
                {!(
                  arr[j].__typename === 'ContentfulSlantedSection' && !m.variant
                ) &&
                arr[j].__typename !== 'ContentfulPageHead' &&
                j !== arr.length &&
                noDividerNextNames(arr[j + 1] ? arr[j + 1].__typename : null) &&
                !props.slantedSection ? (
                  <Divider style={{ margin: 24 }} />
                ) : null}
              </>
            );
          }
        }
      })}
    </>
  ) : null;
};
GenerateReact.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      modules: PropTypes.array,
    }),
  }),
  slantedSection: PropTypes.bool,
  variant: PropTypes.string,
};
export default GenerateReact;
