import React from 'react';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint:
    typeof window !== `undefined` && document
      ? document.getElementById('jss-insertion-point')
      : null,
});

const StyleInjector = (props) => {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};

StyleInjector.propTypes = {
  children: PropTypes.node,
};
export default StyleInjector;
