import React from 'react';
import Phone from '@material-ui/icons/Phone';
import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import { Subject } from '@material-ui/icons';

const pages = [
  { name: 'Home', path: '/', icon: <Home /> },
  { name: 'About', path: '/about', icon: <Info /> },
  { name: 'Services', path: '/services', icon: <Subject /> },
  { name: 'Contact', path: '/contact', icon: <Phone /> },
];
export default pages;
