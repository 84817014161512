import React from 'react';
import SlantedSection from './slanted-section';
import { Link, Typography } from '@material-ui/core';
import theme from '../theme';
const Footer = () => {
  return (
    <SlantedSection bottomFlat topFlat variant="primary">
      <div
        style={{
          textAlign: 'center',
          color: theme.palette.primary.contrastText,
        }}
      >
        <Typography>&copy; Bellisima {new Date().getFullYear()}</Typography>
        <Typography>
          Created by{' '}
          <Link
            href="https://demerstech.com/"
            style={{
              color: theme.palette.primary.contrastText,
              textDecoration: 'underline',
            }}
          >
            Patrick Demers
          </Link>
        </Typography>
      </div>
    </SlantedSection>
  );
};

export default Footer;
