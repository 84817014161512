import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import theme from '../theme';
import PropTypes from 'prop-types';

const InfoAccordion = (props) => {
  const contentfulRenderOptions = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <li>
            <Typography>{children}</Typography>
          </li>
        );
      },
    },
  };
  return (
    <Grid
      container
      direction="column"
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      {props.data.infoAccordionEntries.map((details, i) => (
        <Grid
          item
          xs={11}
          sm={9}
          md={6}
          style={{ marginLeft: 'auto', width: '100%', marginRight: 'auto' }}
          key={i}
        >
          <div
            style={{
              border: `4px solid ${theme.palette.primary.main}`,
              margin: 6,
            }}
          >
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">{details.headerText}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {documentToReactComponents(
                  JSON.parse(details.content.raw),
                  contentfulRenderOptions
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
InfoAccordion.propTypes = {
  data: PropTypes.shape({
    infoAccordionEntries: PropTypes.arrayOf(
      PropTypes.shape({
        headerText: PropTypes.string.isRequired,
        content: PropTypes.shape({ raw: PropTypes.string }),
      })
    ),
  }),
};
export default InfoAccordion;
